import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Col, Row, Button, Spin } from 'antd';
import moment from "moment";

import amsAPI from '../../amsAPI';
import financeAPI from '../../financeAPI';

import 'antd/dist/antd.css';
import './Redirect.css';

class RemittanceReceipt extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    remitter: {},
    recipient: {},
    bankAccount: {},
    loadingRecipient: false,
    loadingRemitter: false,
    loadingBankAccount: false,
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    const { recipient, remitter, bankAccount } = this.props.remittanceInfo;
    if (remitter) {
      this.setState({ loadingRemitter: true });
      this.getMember(remitter)
        .then(res => this.setState({ remitter: res.member, loadingRemitter: false }))
        .catch(err => console.log(err));
    }
    if (recipient) {
      this.setState({ loadingRecipient: true });
      this.getMember(recipient)
        .then(res => this.setState({ recipient: res.member, loadingRecipient: false }))
        .catch(err => console.log(err));
    }
    if (bankAccount) {
      this.setState({ loadingBankAccount: true });
      this.getBankAccount(bankAccount)
        .then(res => this.setState({ bankAccount: res.data, loadingBankAccount: false }))
        .catch(err => console.log(err));
    }
  }

  getMember = async (id) => {
    const response = await amsAPI.getUrl(`/ams/members/${id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getBankAccount = async (id) => {
    const response = await financeAPI.getUrl(`/fin/bank_accounts/${id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  returnHome = async (e) => {
    e.preventDefault();
    this.props.clearState();
    this.props.history.push("/");
  };

  render() {
    const { loadingRemitter, loadingRecipient, loadingBankAccount } = this.state;
    const loading = loadingRemitter || loadingRecipient || loadingBankAccount;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    const { receiptNumber, remittanceDate, remitMethod, notes, remitterEmail ,gfReceiptNumber } = this.props.remittanceInfo;
    const { remitter, recipient, bankAccount } = this.state;
    const localeChurchName = remitter.localeChurchId ? remitter.localeChurchId.name : "";

    let bankAccountName = bankAccount.name ? bankAccount.name.toUpperCase() : "";
    let isNationalOrKapi = bankAccountName.indexOf("NATIONAL") >= 0 || bankAccountName.indexOf("KAPI") >= 0;
    let category = "", gfNo = "";
    if (isNationalOrKapi) {
      category = bankAccountName.split(" ")[0];
      gfNo = gfReceiptNumber;
    } else {
      category = "LOCALE"
      gfNo = "N/A"
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          {receiptNumber ?
            <div>
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                  <div>
                    <h3>Your remittance has been successfully submitted.</h3>
                    <span>Your remittance receipt number is:</span>
                    <h2>{receiptNumber}</h2>
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div>
                    <h4>These were the information submitted:</h4>
                    <h4>{`Remitter: ${remitter.name}`}</h4>
                    <h4>{`Locale Church: ${localeChurchName}`}</h4>
                    <h4>{`Category: ${category}`}</h4>
                    {remitMethod === "mail" ?
                      <h4>{`Recipient: ${recipient.name}`}</h4>
                      :
                      <h4>{`Bank: ${bankAccount.name} ${bankAccount.bank}`}</h4>
                    }
                    {isNationalOrKapi ?
                      <h4>{`GF Receipt No: ${gfNo}`}</h4>
                      :
                      null
                    }
                    <h4>{`Email: ${remitter.email || remitterEmail}`}</h4>
                    <h4>{`Remittance Date: ${moment(remittanceDate).format("YYYY/MM/DD")}`}</h4>
                    <h4>{`Breakdown:`}</h4>
                    <p>{notes}</p>
                  </div>
                </Col>
              </Row>
            </div>
            :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                <div><Button onClick={this.returnHome}>Return to home</Button></div>
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(RemittanceReceipt);
