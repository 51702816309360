import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Route } from 'react-router-dom';
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import { Button, Col, Layout, Row, Menu, Select, Form } from 'antd';

import ReactGA from 'react-ga';

import { ClientFooter } from './common';
import { ClientHome } from './dashboard';
import { Register } from './register'
import { EditRemittance } from './remittance'
import { RemittanceReceipt } from './redirects';

import 'antd/dist/antd.css';
import './Wrapper.css';

ReactGA.initialize('UA-145670123-1');

const { Content, Header } = Layout;
const { Option } = Select;

const WrappedClientHome = Form.create({ name: 'client_home' })(ClientHome);

class ClientWrapper extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor() {
		super();
		this.state = {
      lang: '',
      isAdmin: false,
      remittanceInfo: {},
    };
    this.updateRemittanceInfo = this.updateRemittanceInfo.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goTo = this.goTo.bind(this);
    this.changeLang = this.changeLang.bind(this);
    this.clearState = this.clearState.bind(this);
  }

  updateRemittanceInfo = async (updatedInfo) => {
    const currentInfo = this.state.remittanceInfo;
    this.setState({
      remittanceInfo: { ...currentInfo, ...updatedInfo }
    });
  };

  goTo(url) {
    this.clearState();
    this.props.history.push(url);
  }

  goBack() {
    this.clearState();
    this.props.history.push("/");
  }

  clearState() {
    this.setState({remittanceInfo: {}});
  }

  changeLang = value => {
    this.setState(prevState => ({ lang: value }));
    this.props.i18n.changeLanguage(value);
  };

  render() {
    const pathname = this.props.location.pathname;
    const { t } = this.props;
    const displayAddButton = pathname === "/";

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Header
            style={{
              position: 'fixed',
              zIndex: 1,
              width: '100%',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item
                key="2"
                style={{ padding: '0' }}
              >
                {!(pathname === "/" || pathname === "/redirect") &&
                  <Button type="default" size="large" onClick={this.goBack}>{t("Back")}</Button>
                }
              </Menu.Item>
            </Menu>
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item key="1">
                <Select
                  onChange={this.changeLang}
                  defaultValue="en"
                >
                  <Option key="en" value="en">English</Option>
                  <Option key="jp" value="jp">日本語</Option>
                </Select>
                <Button
                  type="default"
                  size="large"
                  onClick={(e) =>this.goTo("/register")}
                  style={{
                    marginLeft: '10px',
                    display: (displayAddButton) ? "inherit" : "none",
                  }}
                >Register</Button>
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ padding: '0 50px', marginTop: 75 }}>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <div style={{ padding: 24, background: '#fff', minHeight: 320 }}>
                  <Route exact path="/"
                    render={(props) =>
                      <WrappedClientHome
                        {...props}
                        remittanceInfo={this.state.remittanceInfo}
                        updateRemittanceInfo={this.updateRemittanceInfo}
                      />
                    }
                  />
                  <Route exact path="/remittance_receipt"
                    render={(props) =>
                      <RemittanceReceipt
                        {...props}
                        remittanceInfo={this.state.remittanceInfo}
                        clearState={this.clearState}
                      />
                    }
                  />
                  <Route exact path="/register"
                    render={(props) =>
                      <Register {...props} />
                    }
                  />

                  <Route exact path="/remittances/:_id/edit"
                    render={(props) =>
                      <EditRemittance
                        {...props}
                        remittanceInfo={this.state.remittanceInfo}
                        updateRemittanceInfo={this.updateRemittanceInfo}
                      />
                    }
                  />

                </div>
              </Col>
            </Row>
          </Content>
          <ClientFooter />
        </Layout>
      </Layout>
    );
  }
}

export default withTranslation()(withRouter(ClientWrapper));