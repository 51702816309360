import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Select, message, Input,
} from 'antd';
import { withTranslation } from 'react-i18next';

import ReactGA from 'react-ga';
import querystring from 'querystring';

import amsAPI from '../../amsAPI';
import financeAPI from '../../financeAPI';

import 'antd/dist/antd.css';
import './Register.css';

const { Option } = Select;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class Register extends Component {
  state = {
    remitters: [],
    record: {},
    loading: false,
    submitting: false,
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  getMember = async (_id) => {
    const response = await amsAPI.getUrl(`/ams/members/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

  };

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit new remittance account'
    })
    e.preventDefault();
    const { record } = this.state;
    this.setState({ submitting: true });
    financeAPI.fetchUrl(`/fin/remit_accounts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({...record}),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Remittance account successfully saved.');
          this.setState({ submitting: false });
          this.props.history.push(`/`);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting.');
    });
  };

  handleSearchRemitter = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ remitters: data })
      });
    } else {
      this.setState({ remitters: [] });
    }
  };

  render() {
    const { remitters, record } = this.state;
    const { t } = this.props;
    const disableSubmit = !record.remitter;

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form {...formItemLayout}>
                <Form.Item label={t("Remitter")}>
                  <Select
                      showSearch
                      placeholder={t("Input member name or id")}
                      dropdownMatchSelectWidth={false}
                      mode={this.state.mode}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearchRemitter}
                      onChange={(value) => this.updateRecord({remitter: value})}
                      notFoundContent={null}
                    >
                      {remitters.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.churchId} ${item.name}`}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
                <Form.Item label={t("Contact Email")}>
                  <Input
                    placeholder={t("Please input your email")}
                    onChange={(e) => this.updateRecord({ email: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label={t("Contact Number")}>
                  <Input
                    placeholder={t("Please input your contact number")}
                    onChange={(e) => this.updateRecord({ contactNumber: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label={t("Account Name")}>
                  <Input
                    placeholder={t("Please input the account name registered in your bank book")}
                    onChange={(e) => this.updateRecord({ accountName: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label={t("Bank")}>
                  <Input
                    placeholder={t("Please input the name of the bank")}
                    onChange={(e) => this.updateRecord({ bank: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label={t("Branch")}>
                  <Input
                    placeholder={t("Please input the bank branch")}
                    onChange={(e) => this.updateRecord({ branch: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label={t("Account Number")}>
                  <Input
                    placeholder={t("Please input the account number you plan to use")}
                    onChange={(e) => this.updateRecord({ accountNumber: e.target.value })}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Button block type="primary"
                loading={this.state.submitting}
                onClick={this.handleSubmit}
                disabled={disableSubmit}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Register);
