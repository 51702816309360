import React, { Component } from 'react';
import { Form, Select, Input } from 'antd';
import { withTranslation } from 'react-i18next';

import 'antd/dist/antd.css';
import './CreateForm.css';

import * as constants from '../../helpers/constants';

const Option = Select.Option;

class RemitMethod extends Component {
  render() {
    const { data, recipients, bankAccounts, disabled, t } = this.props;
    const remitMethods = Object.entries(constants.remittanceMethods);
    return (
      <div>
        <Form.Item label={"Remit Method"}>
          <Select
            placeholder={disabled ? null : "Remittance method"}
            dropdownMatchSelectWidth={false}
            onChange={(value) => {
              if (value === "mail" || value === "worker-pickup") {
                this.props.updateRecord({ remitMethod: value, bankAccount: null, bank: {}, remitterAccountName: null });
              } else {
                this.props.updateRecord({ remitMethod: value, recipient: null, member: {} });
              }
            }}
            defaultValue={data.remitMethod ? data.remitMethod : null}
          >
            {remitMethods.map(([id, name]) =>
              <Option key={id} value={id}>{name}</Option>
            )}
          </Select>
        </Form.Item>
        {(data.remitMethod === "mail" || data.remitMethod === "worker-pickup") &&
          <Form.Item
            label={
              data.remitMethod === "mail" ? t("Recipient") : t("Worker/Officer")
            }
          >
            <Select
              showSearch
              placeholder={disabled ? null : "Input member name or id"}
              dropdownMatchSelectWidth={false}
              optionFilterProp="value"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={this.props.handleSearchRecipient}
              onChange={(value) => this.props.updateRecord({ recipient: value })}
              notFoundContent={null}
              defaultValue={data.recipient ? data.recipient.name : null}
            >
              {recipients.map(item => {
                return (
                  <Option key={item._id} value={item._id}>
                    {`${item.churchId} ${item.name}`}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        }
        {data.remitMethod === "bank" &&
          <div>
            <Form.Item label={"Account Name"}>
              <Input
                placeholder={disabled ? null : "Please input the account name in your bank book"}
                onChange={(e) => this.props.updateRecord({ remitterAccountName: e.target.value })}
                defaultValue={data.remitterAccountName}
              />
            </Form.Item>
            <Form.Item label={"To Bank Account"}>
              <Select
                placeholder={disabled ? null : "Bank account"}
                dropdownMatchSelectWidth={false}
                onChange={(value) => this.props.handleBankAccountChange(value)}
                defaultValue={data.bankAccount ? data.bankAccount._id : null}
              >
                {bankAccounts.map(item => {
                  return (
                    <Option key={item._id} value={item._id}>
                      {`${item.name} ${item.bank} ${item.accountNumber}`}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        }
      </div>
    );
  }
}

export default withTranslation()(RemitMethod);
