import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Select, message, DatePicker, Upload, Icon, Input, Card,
  Tooltip, Descriptions, Modal,
} from 'antd';
import { withTranslation } from 'react-i18next';

import ReactGA from 'react-ga';
import querystring from 'querystring';
import _ from 'lodash';
import imageCompression from 'browser-image-compression';

import * as constants from '../../helpers/constants';
import amsAPI from '../../amsAPI';
import financeAPI from '../../financeAPI';

import 'antd/dist/antd.css';
import './Home.css';

const { Option } = Select;
const { TextArea } = Input;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class ClientHome extends Component {
  state = {
    remitters: [],
    recipients: [],
    bankAccounts: [],
    submitting: false,
    submittingOTP: false,
    fileList: [],
    previewVisible: false,
    previewImage: '',
    bankAccount: {},
    member: {},
    remitAccount: {},
  };

  componentDidMount() {
    /*
    Modal.warning({
      title: 'Mahalagang Paalala',
      content: (
        <div>
          <p>Sa mga locales na mayroon na pong MCGI account, ang lahat po ng tulungan ay ihuhulog na po sa</p>
          <p><strong>LOCALE ACCOUNT</strong>:</p>
          <ul>
            <li>Hain</li>
            <li>Gugol</li>
            <li>Abuloy</li>
            <li>International Projects</li>
            <li>KAPI Monthly Dues/Cares</li>
            <li>at Locale Fund</li>
          </ul>
        </div>
      ),
      onOk() {},
    });
    */
  }

  getBankAccounts = async (remitterId) => {
    const query = querystring.encode({
      remitter: remitterId,
    });
    const response = await financeAPI.getUrl(`/fin/bank_accounts/filtered?${query}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleRecipientChange = (value) => {
    this.props.updateRemittanceInfo({ recipient: value });
    this.getMember(value)
      .then(res => {
        this.setState({ member: res.member, bankAccount: {} });
      })
      .catch(err => console.log(err));
  }

  handleRemitterChange = (value) => {
    this.getBankAccounts(value)
      .then(res => {
        this.setState({ bankAccounts: res.data });
      })
      .catch(err => console.log(err));

    this.props.updateRemittanceInfo({ remitter: value });
    this.getMember(value)
      .then(res => {
        this.setState({ isEnableOtp: res.member.isEnableOtp });
      })
      .catch(err => console.log(err));
    /*
    apiHelper.get(amsAPI, `/ams/remit_accounts?memberId=${value}`)
      .then(res => {
        const data = res.data[0];
        if (!_.isEmpty(data)) {
          this.props.updateRemittanceInfo({
            remitterAccountName: (data && data.accountName),
            remitterAccountNumber: (data && data.accountNumber),
            remitterEmail: (data && data.email),
            remitterContactNum: (data && data.contactNumber),
          });
          this.setState({ dummy: "" });
        }
      })
      .catch(err => console.log(err));
    */
  }

  handleBankAccountChange = (value) => {
    this.props.updateRemittanceInfo({bankAccount: value});
    this.getBankAccount(value)
      .then(res => {
        this.setState({ bankAccount: res.data, member: {} });
      })
      .catch(err => console.log(err));
  }

  getBankAccount = async (_id) => {
    const response = await financeAPI.getUrl(`/fin/bank_accounts/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getMember = async (_id) => {
    const response = await amsAPI.getUrl(`/ams/members/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSendOTPMail = async (e) => {
    e.preventDefault();

    this.setState({ submitting: true });
    this.props.form.validateFieldsAndScroll({scroll: {offsetTop: 150}}, async (err, values) => {
      if (!err) {
        const { remitter, remitterEmail } = this.props.remittanceInfo;
        const { isEnableOtp } = this.state;

        console.log('state.isEnableOtp', isEnableOtp)
        if (isEnableOtp) {
          financeAPI.fetchMulter(`/fin/v1/one_time_pins/request_otp`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              remitter_id: remitter,
              remitter_email: remitterEmail,
            }),
          })
          .then(async res => {
            if (res.status === 200) {
              const response = await res.json();
              message.success('Please check your email for the One-Time-Pin.');
              this.setState({ is_modal_visible: true, verification_token: response.verification_token });
            } else {
              const error = new Error(res.error);
              throw error;
            }
          })
          .catch(err => {
            console.error(err);
            message.error('Error requesting for one-time-pin.');
          });
        } else {
          this.handleSubmit(e);
        }
      }
    });
  }

  processSubmission = async () => {
    const {
      remitter, remitMethod, bankAccount, recipient, remittanceDate,
      notes, remitterEmail, remitterContactNum, remitterAccountName,
      gfReceiptNumber,
    } = this.props.remittanceInfo;
    const { t } = this.props;

    const { fileList } = this.state;
    const formData = new FormData();
    const remitImgRaw = fileList[0] && fileList[0].originFileObj;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    const remitImg = await imageCompression(remitImgRaw, options);

    formData.append('remitImg', remitImg);
    formData.append('remitter', remitter);
    if (remitMethod === "mail" || remitMethod === "worker-pickup" ) {
      formData.append('recipient', recipient);
    } else {
      formData.append('bankAccount', bankAccount);
      formData.append('gfReceiptNumber', gfReceiptNumber);
    }
    formData.append('remittanceDate', remittanceDate.format());
    formData.append('remitMethod', remitMethod);
    formData.append('notes', notes);
    if (remitterEmail) formData.append('remitterEmail', remitterEmail);
    if (remitterContactNum) formData.append('remitterContactNum', remitterContactNum);
    if (remitterAccountName) formData.append('remitterAccountName', remitterAccountName);
    formData.append('userMemberId', remitter);

    financeAPI.fetchMulter(`/fin/remittances`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: formData,
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Remittance successfully submitted.');
          const { receiptNumber } = response.remittance;
          this.props.updateRemittanceInfo({ receiptNumber });
          this.setState({ submitting: false });
          this.setState({ is_modal_visible: false });
          this.props.history.push(`/remittance_receipt`);
        }
      } else if (res.status === 422) {
        this.setState({ submitting: false });
        message.error(t('Remittance already submitted.'));
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error(t('Error submitting remittance.'));
    });
  }

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit remittance'
    });

    e.preventDefault();

    this.setState({ submitting: true });
    let { verification_token, one_time_pin, isEnableOtp } = this.state;
    const { remitterEmail } = this.props.remittanceInfo;

    if (isEnableOtp) {
      this.setState({ submittingOTP: true });
      financeAPI.fetchMulter(`/fin/v1/one_time_pins/verify_otp`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          verification_token: verification_token,
          otp: one_time_pin,
          check: remitterEmail,
        }),
      })
      .then(async res => {
        if (res.status === 200) {
          message.success('OTP Verification Success.');
          this.processSubmission();
        } else {
          this.setState({ submittingOTP: false });
          const response = await res.json();
          console.log('error response', response);
          message.error(`OTP Verification Error: ${response.message}`);
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ submittingOTP: false });
        message.error('Error submitting to OTP verification API.');
      });
    } else {
      this.processSubmission();
    }

  };

  handleSearchRemitter = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ remitters: data })
      });
    } else {
      this.setState({ remitters: [] });
    }
  };

  handleSearchRecipient = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ recipients: data })
      });
    } else {
      this.setState({ recipients: [] });
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    let newFileList = [];
    if (e && e.fileList && e.fileList.length > 0) {
      newFileList.push({ ...e.fileList[e.fileList.length - 1], status: 'done' });
    }
    return newFileList;
  };

  handleOtpCancel = () => {
    this.setState({ is_modal_visible: false });
  };

  render() {
    const {
      recipients, remitters, member, bankAccount, bankAccounts, fileList,
      previewVisible, previewImage,
      is_modal_visible, submitting, submittingOTP,
    } = this.state;
    const { t, remittanceInfo, form } = this.props;
    const { getFieldDecorator } = form;

    const contactNumber = remittanceInfo.remitterContactNum;
    const contactEmail = remittanceInfo.remitterEmail;
    const accountName = remittanceInfo.remitterAccountName;
    const { remitter, gfReceiptNumber } = remittanceInfo;
    let isGfRequiredAccnt = false;
    if (!_.isEmpty(bankAccount)) {
      isGfRequiredAccnt = bankAccount.name.toLowerCase().indexOf('international') >= 0
        || bankAccount.name.toLowerCase().indexOf('national') >= 0
        || bankAccount.name.toLowerCase().indexOf('kapi') >= 0
        || bankAccount.name.toLowerCase().indexOf('other') >= 0;
    }

    /*
    const disableSubmit = !remittanceInfo.remitter
      || (!remittanceInfo.recipient && !remittanceInfo.bankAccount)
      || !remittanceInfo.remittanceDate || !remittanceInfo.notes
      || _.isEmpty(fileList);
    */

    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [file],
        }));
        return false;
      },
      onPreview: async file => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
          previewImage: file.url || file.preview,
          previewVisible: true,
        });
      },
      onChange: ({ fileList }) => {
        let newFileList = [];
        if (fileList && fileList.length > 0) {
          newFileList.push({ ...fileList[fileList.length - 1], status: 'done' });
        }
        this.setState({ fileList: newFileList })
      },
      fileList: fileList,
      listType: "picture-card",
      accept: "image/*",
    };

    const remitMethods = Object.entries(constants.remittanceMethods);

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <h3>{t("Welcome to Japan Remittance!")}</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form {...formItemLayout}>
                <Form.Item label={t("Remitter")}>
                  {getFieldDecorator('remitter', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your name',
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      placeholder={t("Input member name or id")}
                      dropdownMatchSelectWidth={false}
                      mode={this.state.mode}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearchRemitter}
                      onChange={this.handleRemitterChange}
                      notFoundContent={null}
                    >
                      {remitters.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.name}`}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label={t("Contact Number")}>
                  {getFieldDecorator('contactNumber', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your contact number',
                      },
                    ],
                  })(
                    <Input
                      placeholder={t("Please input your contact number")}
                      onChange={(e) => this.props.updateRemittanceInfo({ remitterContactNum: e.target.value })}
                      value={ contactNumber }
                    />
                  )}
                </Form.Item>
                <Form.Item label={t("Contact Email")}>
                  {getFieldDecorator('contactEmail', {
                    rules: [
                      {
                        type: 'email',
                        message: <div>Please input a valid e-mail</div>,
                      },
                      {
                        required: true,
                        message: <div>Please input your e-mail</div>,
                      },
                      {
                        validator: (_, value) => {
                          var regex = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;
                          return regex.test(value) ? Promise.reject('Email cannot contain Japanese character.') : Promise.resolve();
                        }
                      },
                    ],
                  })(
                    <Input
                      placeholder={t("Please input your email")}
                      onChange={(e) => this.props.updateRemittanceInfo({ remitterEmail: e.target.value })}
                      value={ contactEmail }
                    />
                  )}
                </Form.Item>
                <Form.Item label={t("Remittance Method")}>
                  {getFieldDecorator('remitMethod', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a remittance method',
                      },
                    ],
                  })(
                    <Select
                      placeholder={t("Please select a method")}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => {
                        if (value === "mail" || value === "worker-pickup") {
                          this.props.updateRemittanceInfo({ remitMethod: value, bankAccount: null, bank: {} });
                        } else {
                          this.props.updateRemittanceInfo({ remitMethod: value, recipient: null, member: {} });
                        }
                      }}
                    >
                      {remitMethods.map(([id, name]) =>
                        <Option key={id} value={id}>{name}</Option>
                      )}
                    </Select>
                  )}
                </Form.Item>
                {(remittanceInfo.remitMethod === "mail" || remittanceInfo.remitMethod === "worker-pickup") &&
                  <div>
                    <Form.Item
                      label={
                        remittanceInfo.remitMethod === "mail" ? t("Recipient") : t("Worker/Officer")
                      }
                    >
                      <Select
                        showSearch
                        placeholder={t("Input member name or id")}
                        dropdownMatchSelectWidth={false}
                        mode={this.state.mode}
                        optionFilterProp="value"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={this.handleSearchRecipient}
                        onChange={this.handleRecipientChange}
                        notFoundContent={null}
                      >
                        {recipients.map(item => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {`${item.churchId} ${item.name}`}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    {!_.isEmpty(member) &&
                      <Form.Item
                        style={{
                          background: '#ECECEC',
                          padding: '10px'
                        }}
                      >
                        <Card
                          title={t("Recipient Details")}
                          size="small"
                          bordered={false}
                        >
                          <p>Name : {member.name}</p>
                          <p>Address : {member.address}</p>
                          <p>Contact Number : {member.contactNumber}</p>
                          <p>Email : {member.email}</p>
                        </Card>
                      </Form.Item>
                    }
                  </div>
                }
                {remittanceInfo.remitMethod === "bank" &&
                  <div>
                    <Form.Item label={t("Sender's Account Name")}>
                      <Input
                        placeholder={t("Please input the account name in your bank book")}
                        onChange={(e) => this.props.updateRemittanceInfo({ remitterAccountName: e.target.value })}
                        value={ accountName }
                      />
                    </Form.Item>
                    <Form.Item label={t("To Bank Account")}>
                      <Select
                        placeholder={t("Please select the bank account remitted to")}
                        dropdownMatchSelectWidth={false}
                        onChange={this.handleBankAccountChange}
                        disabled={!remitter}
                      >
                        {bankAccounts.map(item => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {item.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    {(!_.isEmpty(bankAccount) && isGfRequiredAccnt) &&
                      <Form.Item label={t("GF Receipt Number")}>
                        {getFieldDecorator('gfReceiptNumber', {
                          rules: [
                            {
                              required: isGfRequiredAccnt,
                              message: 'Please input the GF Receipt Number',
                            },
                          ],
                        })(
                          <Input
                            placeholder={t("Please input the GF receipt number")}
                            onChange={(e) => this.props.updateRemittanceInfo({ gfReceiptNumber: e.target.value })}
                            value={ gfReceiptNumber }
                          />
                        )}
                      </Form.Item>
                    }
                    {/* {!_.isEmpty(bankAccount) &&
                      <Form.Item label={t("Account is For")}>
                        <Input
                          value={bankAccount.description}
                          readOnly={true}
                        />
                      </Form.Item>
                    } */}
                    {!_.isEmpty(bankAccount) &&
                      <Descriptions title={t("Bank Account Details")} bordered>
                        <Descriptions.Item label="Name" span={3}>
                          {bankAccount.name}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Description" span={3}>
                          // {bankAccount.description}
                        </Descriptions.Item> */}
                        <Descriptions.Item label="Bank">{bankAccount.bank}</Descriptions.Item>
                        <Descriptions.Item label="Branch">{bankAccount.branch}</Descriptions.Item>
                        <Descriptions.Item label="Account Number">{bankAccount.accountNumber}</Descriptions.Item>
                        <Descriptions.Item label="Account Name">{bankAccount.accountName}</Descriptions.Item>
                      </Descriptions>
                    }
                  </div>
                }
                <Form.Item
                  label={
                    remittanceInfo.remitMethod === "worker-pickup" ? t("Pickup Date") : t("Remittance Date")
                  }
                >
                  {getFieldDecorator('remittanceDate', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input the remittance date',
                      },
                    ],
                  })(
                    <DatePicker
                      onChange={(value) => this.props.updateRemittanceInfo({ remittanceDate: value })}
                    />
                  )}
                </Form.Item>
                <Form.Item label={t("Bank Transfer Receipt")}>
                  <div>
                    {getFieldDecorator('upload', {
                      valuePropName: 'fileList',
                      getValueFromEvent: this.normFile,
                      rules: [
                        {
                          required: true,
                          message: 'Please upload bank transfer receipt',
                        },
                      ],
                    })(
                      <Upload {...props}>
                        <Tooltip
                          title={<span>Limited to one (1) file only</span>}
                          placement="right"
                          visible={_.isEmpty(fileList) ? true : false}
                          zIndex={1}
                        >
                          <Button>
                            <Icon type="upload" /> Select File
                          </Button>
                        </Tooltip>
                      </Upload>
                    )}
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                </Form.Item>
                <Form.Item label="Breakdown:">
                  {getFieldDecorator('notes', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input the breakdown',
                      },
                    ],
                  })(
                    <TextArea
                      placeholder=
{`Paki lista or itemize kung para saan ang tulong na ipinadala.
Halimbawa:
  Hain: <ilagay dito ang halaga>
  Abuloy: <ilagay dito ang halaga>
`}
                      autosize={{ minRows: 7 }}
                      onChange={(e) => this.props.updateRemittanceInfo({ notes: e.target.value })}
                    />
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Button block type="primary"
                onClick={this.handleSendOTPMail}
                loading={submitting}
              >
                {t("Submit")}
              </Button>
              <Modal
                visible={is_modal_visible}
                closable={false}
                maskClosable={false}
                title="One time Pin"
                onOk={this.handleSubmit}
                onCancel={this.handleOtpCancel}
                footer={[
                  <Button
                    key="back"
                    disabled={submittingOTP}
                    onClick={this.handleOtpCancel}
                  >Cancel</Button>,
                  <Button
                    key="submit" type="primary"
                    loading={submittingOTP}
                    onClick={this.handleSubmit}
                  >Submit</Button>,
                ]}
              >
                <Input
                  placeholder={t("Please input the one-time-pin sent to your email")}
                  onChange={(e) => this.setState({ one_time_pin: e.target.value })}
                />
              </Modal>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ClientHome);
