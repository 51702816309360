import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Button, Form, Select, Row, Col, PageHeader, Spin, DatePicker, Icon,
  message, Input, Modal, Upload, Tooltip,
} from 'antd';
import { withTranslation } from 'react-i18next';

import ReactGA from 'react-ga';

import querystring from 'querystring';
import moment from 'moment';
import _ from 'lodash';
import imageCompression from 'browser-image-compression';

import RemitMethod from './RemitMethod';
import { Unauthorized } from '../errors'
import * as functions from '../../helpers/functions';

import amsAPI from '../../amsAPI';
import financeAPI from '../../financeAPI';

import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    lg: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class EditRemittance extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      remitters: [],
      recipients: [],
      bankAccounts: [],
      record: {},
      remitImg: {},
      receiptImg: {},
      submitting: false,
      loadingRecord: true,
      loadingRemitImg: true,
      loadingReceiptImg: true,
      previewVisible: false,
      previewImage: '',
      isAuthorized: false,
      rotation: 0,
    }

    this.updateRecord = this.updateRecord.bind(this);
  }

  componentWillMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingRecord: true, loadingBankAccounts: true });
    this.getRecord()
      .then(res => {
        this.setState({
          record: { ...res.data },
          loadingRecord: false,
          isAuthorized: true,
          bankAccount: res.data.bankAccount,
        });
        this.getBankAccounts(res.data.remitter ? res.data.remitter._id : null)
          .then(res => {
            this.setState({ bankAccounts: res.data, loadingBankAccounts: false });
          })
          .catch(err => console.log(err));
        this.props.updateRemittanceInfo({
          ...res.data,
          bankAccount: res.data.bankAccount ? res.data.bankAccount._id : null,
          remitter: res.data.remitter ? res.data.remitter._id : null,
          recipient: res.data.recipient ? res.data.recipient._id : null,
        })
      })
      .catch(err => {
        this.setState({ isAuthorized: false, loadingRecord: false });
      });
    this.getRecordImage('remitImg')
      .then(res => {
        this.setState({ remitImg: res.data ? res.data.remitImg : {}, loadingRemitImg: false });
      })
  }

  handleBankAccountChange = (value) => {
    this.props.updateRemittanceInfo({bankAccount: value});
    this.getBankAccount(value)
      .then(res => {
        this.setState({ bankAccount: res.data, member: {} });
      })
      .catch(err => console.log(err));
  }

  getBankAccount = async (_id) => {
    const response = await financeAPI.getUrl(`/fin/bank_accounts/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getBankAccounts = async (remitterId) => {
    const query = querystring.encode({
      remitter: remitterId,
    });
    const response = await financeAPI.getUrl(`/fin/bank_accounts/filtered?${query}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getRecord = async () => {
    const { _id } = this.props.match.params;
    const response = await financeAPI.getUrl(`/fin/remittances/${_id}/for_edit`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getRecordImage = async (image) => {
    const { _id } = this.props.match.params;
    const response = await financeAPI.getUrl(`/fin/remittances/${_id}/img?image=${image}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
    this.props.updateRemittanceInfo({ ...currentInfo, ...updatedInfo })
  };

  handleSearchRemitter = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ remitters: data })
      });
    } else {
      this.setState({ remitters: [] });
    }
  };

  handleSearchRecipient = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ recipients: data })
      });
    } else {
      this.setState({ recipients: [] });
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    const base64Flag = `data:${file.contentType};base64,`;
    const imageStr = functions.arrayBufferToBase64(file.data.data);
    const image = base64Flag + imageStr;

    this.setState({
      previewImage: image,
      previewVisible: true,
    });
  };

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'client update remittance'
    });

    e.preventDefault();
    const { _id } = this.props.match.params;
    this.setState({ submitting: true });
    const {
      remitter, remitMethod, bankAccount, recipient, remittanceDate,
      notes, remitterEmail, remitterContactNum, remitterAccountName,
      gfReceiptNumber,
    } = this.state.record;
    this.props.updateRemittanceInfo({
      remitter: remitter ? remitter._id : null,
      bankAccount: typeof bankAccount === "string" ? bankAccount : typeof bankAccount === "object" ? bankAccount._id : null,
      recipient: recipient ? recipient._id : null,
      remitterAccountName: remitterAccountName ? remitterAccountName : null,
    })

    const formData = new FormData();
    formData.append('notes', notes);
    formData.append('userMemberId', remitter._id);

    formData.append('remitter', remitter._id);
    formData.append('remitMethod', remitMethod);
    formData.append('remittanceDate', remittanceDate);
    if (gfReceiptNumber) formData.append('gfReceiptNumber', gfReceiptNumber);
    formData.append('remitterEmail', remitterEmail);
    formData.append('remitterContactNum', remitterContactNum);
    formData.append('remitterAccountName', remitterAccountName);

    if (remitMethod === "mail") {
      formData.append('recipient', recipient._id);
    } else {
      formData.append(
        'bankAccount',
        typeof bankAccount === "object" ? bankAccount._id : bankAccount
      );
    }

    const { fileList } = this.state;
    if (!_.isEmpty(fileList)) {
      const remitImgRaw = fileList[0];
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      const remitImg = await imageCompression(remitImgRaw, options);
      formData.append('remitImg', remitImg);
    }

    financeAPI.fetchMulter(`/fin/remittances/${_id}/remitter`, {
      method: 'PATCH',
      mode: 'cors',
      credentials: 'include',
      body: formData,
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Remittance successfully updated.');
          this.setState({ submitting: false });
          this.props.history.push("/remittance_receipt");
        }
      } else if (res.status === 403) {
        this.setState({ submitting: false });
        message.error('Remittance already corrected.');
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting remittance.');
    });
  };

  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if(newRotation === 360){
      newRotation = 0;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  render() {
    if (this.state.loadingRecord) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }
    if (!this.state.isAuthorized) {
      return <Unauthorized />
    }
    if (this.state.loadingBankAccounts) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    const {
      record, recipients, remitters, bankAccounts,
      loadingRemitImg, previewVisible, previewImage, remitImg,
      fileList, rotation,
    } = this.state;
    const {
      receiptNumber, remitter,
      remitterContactNum, remitterEmail,
      remittanceDate, notes, status,
      gfReceiptNumber,
    } = record;
    const { t } = this.props;

    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [file],
        }));
        return false;
      },
      fileList,
      accept: "image/*",
    };

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form {...formItemLayout}>
                  <Form.Item label={"Receipt Number"}>
                    <Input
                      defaultValue={receiptNumber}
                      disabled={true}
                    />
                  </Form.Item>
                  {(!_.isEmpty(this.state.bankAccount) &&
                      (
                        this.state.bankAccount.name.toLowerCase().indexOf('international') >= 0
                        || this.state.bankAccount.name.toLowerCase().indexOf('national') >= 0
                        || this.state.bankAccount.name.toLowerCase().indexOf('kapi') >= 0
                        || this.state.bankAccount.name.toLowerCase().indexOf('other') >= 0
                      )
                     ) &&
                    <Form.Item label={"GF Receipt Number"}>
                      <Input
                        onChange={(e) => this.updateRecord({ gfReceiptNumber: e.target.value })}
                        defaultValue={gfReceiptNumber ? gfReceiptNumber : null}
                      />
                    </Form.Item>
                  }
                  <Form.Item label="Remitter">
                    <Select
                      showSearch
                      dropdownMatchSelectWidth={false}
                      mode={this.state.mode}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearchRemitter}
                      onChange={(value) => this.updateRecord({remitter: value})}
                      notFoundContent={null}
                      defaultValue={remitter ? remitter.name : null}
                      disabled={true}
                    >
                      {remitters.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.churchId} ${item.name}`}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label={"Contact Number"}>
                    <Input
                      onChange={(e) => this.updateRecord({ remitterContactNum: e.target.value })}
                      defaultValue={remitterContactNum ? remitterContactNum : null}
                    />
                  </Form.Item>
                  <Form.Item label={"Contact Email"}>
                    <Input
                      onChange={(e) => this.updateRecord({ remitterEmail: e.target.value })}
                      defaultValue={remitterEmail ? remitterEmail : null}
                    />
                  </Form.Item>
                  <RemitMethod
                    handleSearchRecipient={this.handleSearchRecipient}
                    handleBankAccountChange={this.handleBankAccountChange}
                    updateRecord={this.updateRecord}
                    data={record}
                    recipients={recipients}
                    bankAccounts={bankAccounts}
                    disabled={true}
                  />
                  <Form.Item
                    label={
                      record.remitMethod === "worker-pickup" ? t("Pickup Date") : t("Remittance Date")
                    }
                  >
                    <DatePicker
                      placeholder={null}
                      onChange={(value) => this.updateRecord({ remittanceDate: value })}
                      defaultValue={remittanceDate ? moment(remittanceDate) : null}
                    />
                  </Form.Item>
                  <Form.Item label={"Status"}>
                    <Input
                      defaultValue={status ? status.toUpperCase() : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label={t("Receipt (remitter)")}>
                    <Upload {...props}>
                      <Tooltip
                        title={<span>Limited to one (1) file only</span>}
                        placement="right"
                        visible={true}
                      >
                        <Button>
                          <Icon type="upload" /> Select File
                        </Button>
                      </Tooltip>
                    </Upload>
                  </Form.Item>
                  <Form.Item label="Breakdown/Notes:">
                    <TextArea
                      autosize={{ minRows: 7 }}
                      onChange={(e) => this.updateRecord({ notes: e.target.value })}
                      value={notes}
                    />
                  </Form.Item>
                  <Form.Item label="Receipts">
                    <Button
                      onClick={(e) => this.handlePreview(remitImg)}
                      disabled={_.isEmpty(remitImg)}
                      loading={loadingRemitImg}
                    >
                      <Icon type="eye"/>Preview receipt (remitter)
                    </Button>
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        icon="redo"
                        onClick={this.rotate}
                      />
                      <img
                        alt="example"
                        style={{ width: '100%', transform: `rotate(${rotation}deg)` }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary"
                      loading={this.state.submitting}
                      onClick={this.handleSubmit}
                    >
                      {"Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(withTranslation()(EditRemittance));
